@font-face {
    font-family: 'Roboto-Black';
    src: url('assets/fonts/Roboto/Roboto-Black.ttf');
}

@font-face {
    font-family: 'Roboto-Bold';
    src: url('assets/fonts/Roboto/Roboto-Bold.ttf');
}

@font-face {
    font-family: 'Roboto-Medium-Italic';
    src: url('assets/fonts/Roboto/Roboto-Medium-Italic.ttf');
}

@font-face {
    font-family: 'Roboto-Condensed-Bold';
    src: url('assets/fonts/Roboto/Roboto-Condensed-Bold.ttf');
}

@font-face {
    font-family: 'Roboto-Condensed-Medium';
    src: url('assets/fonts/Roboto/Roboto-Condensed-Medium.ttf');
}

@font-face {
    font-family: 'Roboto-Condensed-Regular';
    src: url('assets/fonts/Roboto/Roboto-Condensed-Regular.ttf');
}

@font-face {
    font-family: 'Roboto-Italic';
    src: url('assets/fonts/Roboto/Roboto-Italic.ttf');
}

@font-face {
    font-family: 'Roboto-Medium';
    src: url('assets/fonts/Roboto/Roboto-Medium.ttf');
}

@font-face {
    font-family: 'Roboto-Regular';
    src: url('assets/fonts/Roboto/Roboto-Regular.ttf');
}

@font-face {
    font-family: 'BebasNeue-Bold';
    src: url('assets/fonts/BebasNeue/BebasNeue-Bold.otf');
}

@font-face {
    font-family: 'BebasNeue-Book';
    src: url('assets/fonts/BebasNeue/BebasNeue-Book.otf');
}

@font-face {
    font-family: 'BebasNeue-Thin';
    src: url('assets/fonts/BebasNeue/BebasNeue-Thin.otf');
}

@font-face {
    font-family: 'BebasNeue-Light';
    src: url('assets/fonts/BebasNeue/BebasNeue-Light.otf');
}

@font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: normal;
    src: url(https://fonts.gstatic.com/s/opensans/v18/mem8YaGs126MiZpBA-UFWJ0bbck.woff2)
        format('woff2');
}

/* cyrillic */
@font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: normal;
    src: url(https://fonts.gstatic.com/s/opensans/v18/mem8YaGs126MiZpBA-UFUZ0bbck.woff2)
        format('woff2');
}

/* greek-ext */
@font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: normal;
    src: url(https://fonts.gstatic.com/s/opensans/v18/mem8YaGs126MiZpBA-UFWZ0bbck.woff2)
        format('woff2');
}

/* greek */
@font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: normal;
    src: url(https://fonts.gstatic.com/s/opensans/v18/mem8YaGs126MiZpBA-UFVp0bbck.woff2)
        format('woff2');
}

/* vietnamese */
@font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: normal;
    src: url(https://fonts.gstatic.com/s/opensans/v18/mem8YaGs126MiZpBA-UFWp0bbck.woff2)
        format('woff2');
}

/* latin-ext */
@font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: normal;
    src: url(https://fonts.gstatic.com/s/opensans/v18/mem8YaGs126MiZpBA-UFW50bbck.woff2)
        format('woff2');
}

/* latin */
@font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: normal;
    src: url(https://fonts.gstatic.com/s/opensans/v18/mem8YaGs126MiZpBA-UFVZ0b.woff2)
        format('woff2');
}

/* LOADING TRANSITION */

.table-cell-nowrap {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.search-filters-box {
    border: 1px solid lightgray;
    border-radius: 5px;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 2px 0px 0px 0px;
    position: relative;
    margin-right: 5px;
}

.search-filter-buttons {
    display: flex;
    justify-content: flex-end;
    /* padding-bottom: 20px; */
    padding-top: 20px;
}

/* .css-a2j6v-MuiPaper-root {
    box-shadow:
        rgba(0, 0, 0, 0.2) 0px 3px 1px -2px,
        rgba(0, 0, 0, 0.14) 0px 2px 2px 0px,
        rgba(0, 0, 0, 0.12) 0px 1px 5px 0px;
} */

.paper-box-shadow-specific {
    box-shadow:
        rgba(0, 0, 0, 0.2) 0px 3px 1px -2px,
        rgba(0, 0, 0, 0.14) 0px 2px 2px 0px,
        rgba(0, 0, 0, 0.12) 0px 1px 5px 0px !important;
}

@media screen and (max-width: 635px) {
    .search-filter-buttons {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        padding-bottom: 20px;
        padding-top: 20px;
    }
    .search-filter-buttons button {
        margin-right: 0px !important;
        margin-bottom: 10px;
    }

    .active-list-item {
        background-color: #d2112b !important;
        color: white !important;
        align-items: center;
        padding-left: 10px;
    }
}

.ctn-preloader {
    align-items: center;
    -webkit-align-items: center;
    display: flex;
    display: -ms-flexbox;
    height: 100%;
    justify-content: center;
    -webkit-justify-content: center;
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    z-index: 999999;
    background: #000000bd;
}

.ctn-preloader .animation-preloader {
    position: absolute;
    z-index: 100;
    text-align: center;
}

.ctn-preloader .animation-preloader .icon {
    display: inline-block;
    position: relative;
}

.ctn-preloader .animation-preloader .txt-loading {
    user-select: none;
}

.ctn-preloader .animation-preloader .txt-loading .letters-loading:before {
    animation: letters-loading 4s infinite;
    color: #d2112b;
    content: attr(data-text-preloader);
    left: 0;
    opacity: 0;
    top: 0;
    line-height: 30px;
    position: absolute;
}

.ctn-preloader .animation-preloader .txt-loading .letters-loading {
    font-family: 'Roboto-Regular';
    letter-spacing: 12px;
    display: inline-block;
    color: #d2112c;
    position: relative;
    font-size: 50px;
    line-height: 30px;
}

.ctn-preloader
    .animation-preloader
    .txt-loading
    .letters-loading:nth-child(2):before {
    animation-delay: 0.2s;
}

.ctn-preloader
    .animation-preloader
    .txt-loading
    .letters-loading:nth-child(3):before {
    animation-delay: 0.4s;
}

.ctn-preloader
    .animation-preloader
    .txt-loading
    .letters-loading:nth-child(4):before {
    animation-delay: 0.6s;
}

.ctn-preloader
    .animation-preloader
    .txt-loading
    .letters-loading:nth-child(5):before {
    animation-delay: 0.8s;
}

.ctn-preloader
    .animation-preloader
    .txt-loading
    .letters-loading:nth-child(6):before {
    animation-delay: 1s;
}

.ctn-preloader
    .animation-preloader
    .txt-loading
    .letters-loading:nth-child(7):before {
    animation-delay: 1.2s;
}

.ctn-preloader
    .animation-preloader
    .txt-loading
    .letters-loading:nth-child(8):before {
    animation-delay: 1.4s;
}

.loaded .animation-preloader {
    opacity: 0;
    transition: 0.3s ease-out;
}

@keyframes spinner {
    to {
        transform: rotateZ(360deg);
    }
}

@keyframes letters-loading {
    0%,
    75%,
    100% {
        opacity: 0;
        transform: rotateY(-90deg);
    }

    25%,
    50% {
        opacity: 1;
        transform: rotateY(0deg);
    }
}

@media screen and (max-width: 500px) {
    .ctn-preloader .animation-preloader .txt-loading .letters-loading {
        font-size: 40px;
        letter-spacing: 10px;
    }

    .active-list-item {
        background-color: #d2112b !important;
        color: white !important;
        align-items: center;
        padding-left: 10px;
    }
}

p,
h1,
h2,
h3,
h4,
h5,
h6 {
    margin: 0px !important;
}

.Roboto-Black {
    font-family: 'Roboto-Black' !important;
}

.Roboto-Bold {
    font-family: 'Roboto-Bold' !important;
}

.Roboto-Condensed-Bold {
    font-family: 'Roboto-Condensed-Bold' !important;
}

.Roboto-Condensed-Medium {
    font-family: 'Roboto-Condensed-Medium' !important;
}

.Roboto-Condensed-Regular {
    font-family: 'Roboto-Condensed-Regular' !important;
}

.Roboto-Italic {
    font-family: 'Roboto-Italic' !important;
}

.Roboto-Medium {
    font-family: 'Roboto-Medium' !important;
}

.Roboto-Regular {
    font-family: 'Roboto-Regular' !important;
}

.Roboto-Medium-Italic {
    font-family: 'Roboto-Medium-Italic' !important;
}

.BebasNeue-Light {
    font-family: 'BebasNeue-Book' !important;
}

.BebasNeue {
    font-family: 'BebasNeue-Bold' !important;
}

.side-bar-scroll:-webkit-scrollbar {
    width: 7px;
}

.side-bar-scroll::-webkit-scrollbar-track {
    background: #d2112b;
}

.side-bar-scroll::-webkit-scrollbar-thumb {
    background: #d2112b;
    border-radius: 5px !important;
    cursor: pointer;
}

.body::-webkit-scrollbar {
    width: 7px;
}

body::-webkit-scrollbar-track {
    background: #d2112b;
}

body::-webkit-scrollbar-thumb {
    background: #d2112b;
    border-radius: 0;
}

*::-webkit-scrollbar {
    width: 5px;
    height: 5px;
    border-radius: 100;
}

*::-webkit-scrollbar-track {
    background: white;
}

*::-webkit-scrollbar-thumb {
    background: lightgray;
    border-radius: 100;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.padding-0 {
    padding: 0px;
}

.padding-5 {
    padding: 5px;
}

.padding-10 {
    padding: 10px;
}

.padding-20 {
    padding: 20px;
}

.selected-row {
    border-top: 1px solid red;
    border-bottom: 1px solid red;
}

.unselected-row {
    border-top: 1px solid transparent;
    border-bottom: 1px solid transparent;
}

td {
    border-bottom: none !important;
}

.text_feild_small {
    height: 36px !important;
}

.disabled-bg {
    background-color: #efefef !important;
}

.ps__rail-y {
    display: none !important;
}

.border-bottom-black {
    border-bottom: 1px solid #3e3e3e;
}

/* Material Autocomplete */

.MuiAutocomplete-tag {
    background: #fff;
    color: black;
    border-radius: 5px;
    font-family: Roboto-Medium;
    font-size: 16px;
    font-weight: normal;
    line-height: normal;
    letter-spacing: normal;
    background: transparent;
    color: rgb(0, 0, 0);
}

.css-ue4sux-MuiPickersToolbar-root-MuiDatePickerToolbar-root {
    background-color: #d2112b !important;
    color: #d2112b !important;
}

.css-7bgu36-MuiButtonBase-root-MuiPickersDay-root.Mui-selected {
    background-color: #d2112b !important;
}
.css-7bgu36-MuiButtonBase-root-MuiPickersDay-root.Mui-selected:hover {
    background-color: #d2112b !important;
}

.css-1azxcoc-MuiButtonBase-root-MuiButton-root {
    color: #d2112b !important;
}

.MuiDatePickerToolbar-title {
    color: white !important;
}

.css-1b0xoj9-MuiPickersYear-yearButton.Mui-selected {
    background-color: #d2112b !important;
}

.mobile-container-input:focus + .mobile-container {
    border: 1px solid #d2112b !important;
}

.mobile-container:hover {
    border: 1px solid #d2112b !important;
}
.breadcrumb_time {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

#panel1a-header {
    background-color: #d2112b;
    color: #fff;
}

#panel1a-header > div.MuiAccordionSummary-content,
.MuiAccordionSummary-root {
    min-height: 30px !important;
    max-height: 54px !important;
}

@media screen and (max-width: 425px) {
    .table-scrollbar {
        height: max-content !important;
    }
    .breadcrumb_time {
        display: flex;
        align-items: center !important;
        justify-content: flex-start !important;
    }
    .css-k3ndhj {
        margin-left: 0px;
        width: 100%;
    }
    .active-list-item {
        background-color: #d2112b !important;
        color: white !important;
        align-items: center;
        padding-left: 10px;
    }
    .list-item {
        background-color: #d2112b !important;
        color: white !important;
        align-items: center;
        padding-left: 10px;
    }
}

@media screen and (max-width: 360px) {
    .active-list-item {
        background-color: #d2112b !important;
        color: white !important;
        align-items: center;
        padding-right: 10px;
    }
}

.static-col-width {
    width: 200px !important;
    max-width: 200px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.width-33 {
    width: 33px !important;
    max-width: 33px !important;
}

.css-g60yx6-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root {
    background: #fff !important;
}

.css-e0n9jt-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.MuiSelect-select {
    background: #fff !important;
}

.css-1pj14c4-MuiInputBase-root-MuiOutlinedInput-root {
    background: #fff !important;
}

.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
    border-color: #d2112b !important;
}

.css-1x7p6i5-MuiInputBase-input-MuiOutlinedInput-input:focus
    + .MuiOutlinedInput-notchedOutline {
    border-color: #d2112b !important;
}

.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: #d2112b !important;
}

.css-1h582ii-MuiPickersToolbar-root-MuiTimePickerToolbar-root,
.css-9140k-MuiTypography-root-MuiPickersToolbarText-root,
.css-ihhycx-MuiTimePickerToolbar-amPmSelection .MuiTimePickerToolbar-ampmLabel,
.css-mm61pc-MuiTypography-root-MuiPickersToolbarText-root-MuiTimePickerToolbar-separator,
.css-1w4nrh4-MuiClockPointer-thumb,
.css-ff5riy-MuiClock-pin,
.css-1egoljz-MuiClockPointer-root,
.css-338pcd-MuiClockPointer-root,
.css-uhmbur-MuiClockPointer-thumb {
    background-color: #d2112b !important;
    border-color: #d2112b !important;
    color: #fff !important;
}

.css-rixs2p {
    background-color: #d2112b !important;
    color: #d2112b !important;
}

/* mobile date time picker style */
.css-s9q20a-MuiPickersToolbar-root-MuiDateTimePickerToolbar-root {
    background-color: #d2112b !important;
    color: white !important;
}

.css-1o36b95-MuiTypography-root-MuiPickersToolbarText-root {
    color: #fff;
}

.MuiPickersToolbar-content .css-15jcv2p-MuiPickersToolbar-content,
.css-14cinwp-MuiTypography-root-MuiPickersToolbarText-root,
.css-1ljf62l-MuiTypography-root-MuiPickersToolbarText-root-MuiDateTimePickerToolbar-separator,
.css-lp81p6-MuiTypography-root-MuiPickersToolbarText-root,
.css-1o36b95-MuiTypography-root-MuiPickersToolbarText-root.Mui-selected {
    color: #fff !important;
}

.css-1i5jzbk-MuiTabs-indicator {
    background-color: #d2112b !important;
}

.css-jh0g7c-MuiButtonBase-root-MuiTab-root.Mui-selected {
    color: #d2112b !important;
}
/* mobile date time picker style */

/* modal background */
.makeStyles-modal_background-49 {
    background-color: #d2112b !important;
}
/* modal background */

.search-box {
    position: absolute;
    top: 10px;
    right: 10px;
    z-index: 5;
    width: 300px;
    border-radius: 3px;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
    background-color: #fff;
    padding: 10px;
    display: flex;
    align-items: center;
}

.search-input {
    width: 100%;
    padding: 8px;
    border: none;
    border-radius: 3px;
    box-sizing: border-box;
    font-size: 16px;
    outline: none;
}

.blue-background {
    background: #ffffff !important;
    border: 1px solid #d2112b !important;
    /* background: #d2112b !important; */
    border-radius: 5px 5px 5px 5px;
    -webkit-border-radius: 5px 5px 5px 5px;
    -moz-border-radius: 5px 5px 5px 5px;
    -ms-border-radius: 5px 5px 5px 5px;
    -o-border-radius: 5px 5px 5px 5px;
    height: 50px !important;
}

.MuiGrid-root.countryFlag.MuiGrid-item.MuiGrid-grid-md-1 {
    display: flex !important;
    flex-direction: column !important;
    flex-wrap: nowrap !important;
    align-content: center !important;
    justify-content: flex-start !important;
    align-items: center !important;
}

.MuiGrid-root.countryFlag.MuiGrid-item.MuiGrid-grid-md-1 p {
    text-align: center !important;
    font-weight: 500 !important;
    font-size: small !important;
}

.blue-background > .MuiCardHeader-content > span {
    color: #d2112b !important;
    font-family: 'BebasNeue-Bold' !important;
    text-transform: uppercase !important;
    font-size: 32px !important;
    white-space: nowrap;
}

.blue-background > .MuiCardHeader-content > span {
    color: #d2112b !important;
    font-family: 'BebasNeue-Bold' !important;
    text-transform: uppercase !important;
    font-size: 32px !important;
}

.red-background {
    background: #d2112b !important;
    border-radius: 5px 5px 0 0;
    -webkit-border-radius: 5px 5px 0 0;
    -moz-border-radius: 5px 5px 0 0;
    -ms-border-radius: 5px 5px 0 0;
    -o-border-radius: 5px 5px 0 0;
    height: 50px !important;
}

.red-background > .MuiCardHeader-content > span {
    color: white !important;
    font-family: 'BebasNeue-Bold' !important;
    text-transform: uppercase !important;
    font-size: 32px !important;
    white-space: nowrap;
}

.red-background > .MuiCardHeader-content > span {
    color: white !important;
    font-family: 'BebasNeue-Bold' !important;
    text-transform: uppercase !important;
    font-size: 32px !important;
}

@media screen and (max-width: 425px) {
    .red-background > .MuiCardHeader-content > span {
        font-size: 24px !important;
    }
}

.padding-con {
    padding-right: 25px !important;
    padding-left: 25px !important;
    padding-top: 25px !important;
    padding-bottom: 25px !important;
}

.searchbyCustomer .MuiFormControlLabel-root {
    /* cursor: pointer; */
    display: inline-flex;
    align-items: center;
    margin-left: -3px;
    margin-right: 2px !important;
    vertical-align: middle;
    -webkit-tap-highlight-color: transparent;
    margin-bottom: 0px;
}

.olo_orders_font {
    font-family: 'Open Sans';
}

.css-t3ycia-MuiChip-label {
    font-family: 'Open Sans';
    font-weight: 600;
    color: #181818;
}

.res-container-min {
    padding: 6px 14px;
}

.searchbyCustomer span.field_label_txt {
    font-family: 'Open Sans';
    font-size: 16px !important;
    font-weight: 700;
    line-height: normal;
    letter-spacing: normal;
    background: transparent;
    color: #181818;
    padding-top: 6px;
    padding-left: 0px !important;
    padding-right: 10px !important;
    text-transform: capitalize;
}

.file_upload {
    display: flex;
    align-items: center;
    justify-content: space-around;
    background: white;
    color: #d2112b;
    border: 1px solid #d2112b !important;
    border-color: #d2112b !important;
    transition: 1s !important;
    font-family: 'BebasNeue-Bold';
    font-weight: 900;
    letter-spacing: '1px';
    font-size: '18px';
    text-transform: 'uppercase';
    gap: 12px;

    color: #d2112b;
    padding: 8px 32px;
    border-radius: 4px;
    width: 120px;
    cursor: pointer;
    text-align: center;
}

.file_upload:hover {
    background: #d2112b;
    color: white;
    border-color: red !important;
    transition: 1s !important;
    box-shadow: '4px 4px 3px -1px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)';
}

.mainheadingsales {
    position: absolute;
    top: -15;
    background-color: #fff !important;
    padding: 0px 10px 0px 9px;
    font-size: 14px;
    color: black;
    font-weight: bolder;
    font-family: 'BebasNeue-thin';
    letter-spacing: 1px;
}

.copy_icon {
    color: #d2112b !important;
    background: rgb(255 255 255) !important;
    font-size: 24px !important;
}

.field_label_txt-left {
    font-family: 'BebasNeue-Book';
    font-weight: 600;
    font-size: 13px;
    color: #666666 !important;
    text-align: left;
    line-height: 1.5;
}

.css-19kzrtu {
    padding-left: 0px !important;
    padding-right: 0px !important;
    padding-bottom: 0px !important;
    padding-top: 10px !important;
}

.tab_classes_padding {
    padding-left: 0px !important;
    padding-right: 0px !important;
    padding-bottom: 0px !important;
    padding-top: 0px !important;
}

.edit-switch span {
    font-family: 'BebasNeue-Bold';
    font-size: 19px;
    letter-spacing: 1px;
    text-transform: uppercase;
    color: white !important;
}

.edit-switch-map span {
    font-family: 'BebasNeue-Bold';
    font-size: 19px;
    letter-spacing: 1px;
    text-transform: uppercase;
    color: #d2112b !important;
}

.css-5ozfyl-MuiSwitch-root .Mui-checked .MuiSwitch-thumb {
    background: white !important;
}

.MuiInputBase-adornedStart {
    padding: 0px;
}

input#arithmetic_condition_txt {
    width: 10px !important;
}

.no-records-found {
    font-family: 'BebasNeue-Bold';
}

#input_label_style {
    font-family: 'BebasNeue-Book';
}

.error_402 {
    color: #d2112b;
    font-size: 100px !important;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0;
    font-family: 'BebasNeue-Bold';
    text-transform: uppercase;
    filter: drop-shadow(3px 5px 2px rgb(0 0 0 / 0.4));
}

span.MuiTimelineDot-root {
    background-color: #d2112b;
    border-width: 1px;
    padding: 2px;
}

button#active_button {
    color: #d2112b !important;
}

.no-records-found {
    font-size: 22px !important;
    width: 100% !important;
    text-align: center;
    text-transform: uppercase;
}

.list-item {
    background-color: white !important;
    color: #d2112b !important;
    align-items: center;
}

.list-item:hover {
    background-color: rgba(94, 94, 94, 0.116) !important;
}

.active-list-item {
    background-color: #d2112b !important;
    color: white !important;
    align-items: center;
}

.css-1db5i3l-MuiFormControl-root-MuiTextField-root {
    outline: none !important;
    border: none !important;
    box-shadow: none !important;
}

.MuiCardHeader-action {
    margin-top: -20px;
}

.container-heading-text > .MuiCardHeader-content > span {
    font-family: 'BebasNeue-Bold' !important;
    text-transform: uppercase;
    font-size: 25px;
    color: #d2112b;
    padding: 0px 0px 10px 0px !important;
}

.black_heading {
    font-family: 'BebasNeue-Bold' !important;
    text-transform: uppercase;
    font-size: 25px;
    color: #000;
}

.header-icon-align {
    font-size: 25px !important;
    color: #d2112b !important;
    margin-top: 15px;
}

.field_label_txt {
    /* padding: 0px 0px 4px 10px; */
    font-family: 'Open Sans';
    font-size: 15px;
    font-weight: 900;
    line-height: normal;
    letter-spacing: 1px;
    color: #181818;
    text-transform: capitalize;
}

.field_label_txt_with {
    padding: 0px 0px 4px 10px;
    font-family: 'BebasNeue-Book';
    font-size: 16;
    font-weight: 900;
    line-height: normal;
    letter-spacing: 1px;
    color: #000;
}

.price-text-strike {
    text-decoration: line-through;
    font-weight: 500;
}

.layout_sub_heading_txt {
    font-size: 26px;
    color: white;
    padding-right: 10px;
    margin-block-start: 0em !important;
    margin-block-end: 0em !important;
    text-align: left;
    text-transform: uppercase;
    font-family: 'BebasNeue-Bold' !important;
}

.orderDetailPanel {
    font-weight: 600 !important;
    font-size: 14px !important;
    line-height: 1.5 !important;
    font-family: 'Open Sans';
    color: #181818;
    /* text-transform: uppercase !important; */
}

.orderDetailPanel5 {
    font-size: 13px !important;
    line-height: 1.5 !important;
    text-transform: capitalize !important;
    margin-bottom: 0px !important;
}

.orderDetailPanel3 {
    font-weight: 600 !important;
    font-size: 14px !important;
    line-height: 1.5 !important;
    text-transform: uppercase !important;
    margin-top: 5 !important;
}

.css-ufwick-MuiButtonBase-root-MuiPickersDay-root.Mui-selected {
    background-color: #d2112b !important;
}

.zoom {
    transition: transform 0.2s;
    /*Animation*/
    object-fit: contain;
    -webkit-transition: transform 0.2s;
    -moz-transition: transform 0.2s;
    -ms-transition: transform 0.2s;
    -o-transition: transform 0.2s;
}
.zoom:hover {
    transform: scale(1.5);
    /*(150%zoom-Note: if the zoom is too large, it will go outside of the viewport) */
    -webkit-transform: scale(1.5);
    -moz-transform: scale(1.5);
    -ms-transform: scale(1.5);
    -o-transform: scale(1.5);
}

.css-ukiac-MuiButtonBase-root-MuiIconButton-root-MuiAutocomplete-clearIndicator {
    margin-right: -10px !important;
}
